import React from 'react';
import styled from 'styled-components';
import {graphql} from 'gatsby';
import {NavigationContext} from '../services/navigation';
import {Strapi_Theater} from '../../typegen/graphql';
import {PageContent} from '../components/Layout';
import getYouTubeID from 'get-youtube-id';
import {getImage} from 'gatsby-plugin-image';
import {BgImage} from 'gbimage-bridge';
import theme from '../styles/theme';
import ReactYoutube from 'react-youtube';
import RichText from '../components/RichText';
import {useLocation} from '@reach/router';
import {Appear, WorkContainer} from '../components/atoms';
import {devices} from '../styles/breakpoints';
import {Helmet} from "react-helmet"
import {getYear} from '../utils/getYear';

const Arrow = styled.div`
  position: absolute;
  z-index: 100;
  top: calc(50% - 40px);
  left: calc(50% - 30px);
  width: 0; 
  height: 0; 
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;
  border-left: 60px solid ${theme.palette.primary};
  cursor: pointer;
`;
interface Props {
  data: {
    strapi: {
      theater: Strapi_Theater
    }
  }
}

const TextContent = styled.div`
  padding: 0 24px;
`;

const Container = styled(PageContent)`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;
`;

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  color: white;
`;

const Thumbnail = styled(BgImage)`
  width: 100%;
  height: 100%;
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Title = styled.p`
  font-weight: bold;
  margin: 0;
  text-transform: uppercase;
  font-size: 20px;
  @media ${devices.tabletPortrait} {
    font-size: 21px;
  }
  @media ${devices.desktop} {
    font-size: 22px;
  }
  `;

const Date = styled.p`
  margin: 24px 0 0;
  font-size: 14px;
  color: #a2a2a2;
`;

const Subtitle = styled.p`
  margin: 4px 0 24px;
  font-weight: bold;
  font-size: 18px;
  width: fit-content;
  position: relative;
  @media ${devices.tabletPortrait} {
    font-size: 19px;
  }
  @media ${devices.desktop} {
    font-size: 20px;
  }
  &::after {
    content: '';
    pointer-events: none;
    position: absolute;
    transform: scaleX(1);
    width: 56px;
    height: 2px;
    bottom: -4px;
    left: -12px;
    background-color: ${theme.palette.primary};
    opacity: 0.35;
  }
`;

const Description = styled(RichText)`
  margin: 0;
  font-size: 16px;
  @media ${devices.tabletPortrait} {
    font-size: 18px;
  }
`;

const Theater: React.FC<Props> = ({data}) => {
  const {strapi: {theater}} = data;
  const {state} = useLocation();
  const [playing, setPlaying] = React.useState(false);
  const [video, setVideo] = React.useState()
  const ref = React.useRef();
  const {setLocation} = React.useContext(NavigationContext);
  React.useEffect(() => {
    setLocation(window.location.pathname)
  }, [])

  const onReady = (event: any) => setVideo(event.target);

  const id = theater.videoURL ? getYouTubeID(theater.videoURL) : '';
  return (
    <Container ref={ref}>
      <Helmet
        title={`${theater.seo?.title} | Stavros Markonis`}
        meta={[
          {
            name: `description`,
            content: theater.seo?.description,
          },
          {
            name: 'keywords',
            content: theater.seo?.keywords.map(({text}: {text: string}) => text).join(', '),
          },
          {
            property: `og:title`,
            content: theater.seo?.title,
          },
          {
            property: `og:description`,
            content: theater.seo?.description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: `${process.env.GATSBY_CLIENT_ORIGIN}${theater?.thumbnail.file?.publicURL}`
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:title`,
            content: theater.seo?.title,
          },
          {
            name: `twitter:description`,
            content:  theater.seo?.description,
          },
        ]}
      />
      <Appear>
        <WorkContainer translation={(state as any)?.enableTranslationTransition}>
          <VideoContainer>
            {!playing && theater.videoURL && (
              <Arrow
                onClick={() => {
                  setPlaying(true);
                  (video as any).playVideo()
                }}
              />
            )}
            <Thumbnail
              style={{zIndex: playing ? 0 : 1}}
              image={getImage((theater.thumbnail as any).file)}
            />
            {theater.videoURL && (
              <ReactYoutube
                containerClassName="youtubeContainer"
                videoId={id === null ? undefined : id}
                onReady={onReady}
                opts={{
                  playerVars: {
                    controls: 1,
                    fs: 1,
                    iv_load_policy: 3,
                    modestbranding: 1,
                  }
                }}
              />
            )
          }
          </VideoContainer>
          <TextContent>
          <Date>{getYear(theater)}</Date>
          <Title>{theater.title}</Title>
          <Subtitle>{theater.subtitle}</Subtitle>
          <Description source={theater.description} />
          </TextContent>
        </WorkContainer>
      </Appear>
    </Container>
  );
};

export default Theater;

export const theaterQuery = graphql`
  query GET_THEATER($id: ID!) {
    strapi {
      theater(id: $id) {
        ...Theater
      }
    }
  }
`;
